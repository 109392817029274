<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="housing_building_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('housing_building')">
                            <HouseBuildingSelectbox
                                v-model="formData.housing_building_id"
                                :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('room_name')">
                            <b-form-input  v-model="formData.name">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>

                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="number_of_beds" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('number_of_beds')">
                            <b-form-input type="number"  v-model="formData.number_of_beds">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>

                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="floor" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('floor')">
                            <b-form-input  v-model="formData.floor">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>

                        </b-form-group>
                    </ValidationProvider>
                    <b-form-group :label="$t('explanation')">
                        <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.explanation"
                                              :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components

// Services

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import HousingRoom from "@/services/HousingRoomServices";
import HouseBuildingSelectbox from "@/components/interactive-fields/HouseBuildingSelectbox.vue";

export default {
    components: {
        HouseBuildingSelectbox,
        ValidationProvider,
        ValidationObserver,

    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {
                name: null,
                address: null
            },
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            HousingRoom.show(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        housing_building_id:data.housing_building_id,
                        name: data.name,
                        number_of_beds:data.number_of_beds,
                        floor:data.floor,
                        explanation:data.explanation,
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                HousingRoom.update(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
